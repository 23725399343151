$(document).ready(function() {
  const winWidth = window.innerWidth || document.body.clientWidth;

  // Header menu scroll to section
  $(function() {
    $(".header-nav-menu-item a").on("click touch", function() {
      const link = $(this).attr("href");

      $("html, body").animate(
        {
          scrollTop: $(link).offset().top,
        },
        500,
      );
    });
  });

  // Mobile menu logic
  $(function() {
    const ANIMATION_DURATION = 1200;
    const burger = $(".burger");
    const headerNav = $(".header-nav");

    // Header burger
    $(".burger").on("click touch", function(e) {
      e.preventDefault();

      if (headerNav.hasClass("animate")) {
        return;
      }

      if (burger.hasClass("open")) {
        closeMobileMenu();
      } else {
        openMobileMenu();
      }
    });

    function openMobileMenu() {
      burger.addClass("open");
      headerNav.addClass("open show animate");

      setTimeout(() => {
        headerNav.removeClass("animate");
      }, ANIMATION_DURATION);
    }

    function closeMobileMenu() {
      headerNav.addClass("animate hide");

      setTimeout(() => {
        burger.removeClass("open");
        headerNav.removeClass("open show hide");
      }, ANIMATION_DURATION);

      setTimeout(() => {
        headerNav.removeClass("animate");
      }, ANIMATION_DURATION);
    }

    // hide open mobile menu on resize, scroll window
    $(function() {
      $(window).on("resize scroll", function() {
        closeMobileMenu();
      });
    });
  });

  // Photos lines animation
  $(function() {
    const startAnimation = function() {
      const photosWrapper = $(this);
      const isAnimationStopped = photosWrapper.hasClass("stop");
      const direction = photosWrapper.data("direction");
      const photosContainer = photosWrapper.find(".photos-container");
      const photosContainerWidth = photosContainer.width();
      const marginLeft = "-" + photosContainerWidth + "px";

      const SPEED_COEFFICIENT = winWidth >= 1024 ? 20 : 60;
      const speedAnimation = photosContainerWidth * SPEED_COEFFICIENT;

      if (isAnimationStopped) {
        photosWrapper.removeClass("stop");
      } else {
        photosWrapper.css("margin-left", direction === "normal" ? 0 : marginLeft);
      }

      photosWrapper.animate(
        { "margin-left": direction === "normal" ? marginLeft : 0 },
        speedAnimation,
        "linear",
        startAnimation,
      );
    };

    const stopAnimation = function() {
      $(this)
        .addClass("stop")
        .stop();
    };

    $(".photos-wrapper").each(function() {
      const photosWrapper = $(this);

      const photosContainer = photosWrapper.find(".photos-container");
      photosWrapper.append(photosContainer.clone());

      startAnimation.call(photosWrapper);
    });
  });
});
